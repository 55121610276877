import { render, staticRenderFns } from "./Report_2_RoadsList.vue?vue&type=template&id=a5032570&scoped=true"
import script from "./Report_2_RoadsList.vue?vue&type=script&lang=js"
export * from "./Report_2_RoadsList.vue?vue&type=script&lang=js"
import style0 from "./Report_2_RoadsList.vue?vue&type=style&index=0&id=a5032570&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5032570",
  null
  
)

export default component.exports